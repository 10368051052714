import gifImage9 from '../assets/ducks/9.gif';
import React from "react";
import {FaRegCopy, FaTelegramPlane} from "react-icons/fa";
import {FaX} from "react-icons/fa6";
import button from '../assets/button.svg';
import telesocial from '../assets/ducks/telegram.gif';
import xsocial from '../assets/ducks/x.gif';

const CommunitySection: React.FC = () => {
    const handleCopy = () => {
        const textToCopy =
            "CA: soon"; // Text to copy
        navigator.clipboard.writeText(textToCopy).then(() => {
            alert("Copied to clipboard!");
        }).catch(() => {
            alert("Failed to copy!");
        });
    };
    const telegramRedirect = () => {
        window.open("https://t.me/ducksolchat", "_blank", "noopener,noreferrer");
    };
    const xRedirect = () => {
        window.open("https://x.com/Solanasduck", "_blank", "noopener,noreferrer");
    };
    return (
        <div
            className="bg-yellow-400 py-10 px-6 md:px-20 flex flex-col md:flex-row items-center pt-24 justify-between space-y-6 md:space-y-0 text-black">
            {/* Left Side - Logo and Name */}
            <div className="flex flex-col items-center md:items-start">
                <img
                    src={gifImage9} // Replace with your image path
                    alt="Suibara Logo"
                    className="w-6/12 mb-4"
                />

            </div>

            <div className="flex flex-col md:flex-row items-center justify-between w-full">
                {/* Center - Community Message */}
                <div className="text-center md:text-left space-y-4 max-w-md">
                    <p className="text-lg tracking-widest font-halo-dek text-black font-semibold">
                        Dive into the flock and connect with quack-loving enthusiasts! Stay in the loop with the latest
                        updates, share memes, and waddle along with the $DUCK movement.
                    </p>
                    {/*<button*/}
                    {/*    className="bg-white text-black font-bold py-2 px-4 rounded-md shadow-lg transform hover:scale-105 transition-transform duration-200">*/}
                    {/*    Join the community*/}
                    {/*</button>*/}
                    <button onClick={telegramRedirect}
                            style={{
                                backgroundImage: `url(${telesocial})`,
                                backgroundSize: 'cover',
                                backgroundPosition: 'center',
                                backgroundRepeat: 'no-repeat',
                                width: '49px',
                                height: '52px',
                                border: 'none',
                                cursor: 'pointer',
                                marginRight: '18px'
                            }}>
                    </button>
                    <button onClick={xRedirect}
                            style={{
                                backgroundImage: `url(${xsocial})`,
                                backgroundSize: 'cover',
                                backgroundPosition: 'center',
                                backgroundRepeat: 'no-repeat',
                                width: '49px',
                                height: '52px',
                                border: 'none',
                                cursor: 'pointer',
                            }}>
                        <FaX className="w-5 h-5 mt-5 ml-3"/>
                    </button>
                </div>

                {/* Right Side - Social Media Icons */}
                <div className="flex flex-col items-center space-y-4">
                    <div className="relative flex flex-col items-end">

                        <div className="relative">
                            <button
                                className=" relative font-halo-dek rounded transform skew-x-[10deg] z-10 bg-yellow-200 text-black font-bold py-2 px-8">
                                DexScreener
                            </button>
                            <button
                                className="mr-2 relative font-halo-dek rounded transform skew-x-[10deg] z-10 bg-yellow-500 text-black font-bold py-2 px-8">
                                Buy now
                            </button>
                        </div>

                        <div
                            className=" bg-yellow-100  rounded font-medium py-2 px-4 flex items-center shadow-md transform skew-x-[10deg]">
                            <span
                                className="flex-1 font-halo-dek whitespace-normal lg:whitespace-nowrap">CA: soon</span>

                            <button className="ml-2" onClick={handleCopy}>
                                <FaRegCopy className="w-6 h-6" />
                            </button>
                        </div>
                    </div>
                </div>
            </div>

        </div>
    );
};

export default CommunitySection;
